import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
  UncontrolledTooltip,
  Popover,
  PopoverBody,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  capitalize,
  copyToClipboard,
  errorHandler,
  formatDate,
  formatNumberInComma,
  formatNumberInShort,
  showToast,
  structureQueryParams,
} from "../../helper-methods";
import {
  deleteLeadById,
  getAffiliateList,
  getAllLeads,
  getOrganizationsList,
  updateLead,
} from "../../http/http-calls";
import { leadStatusConfig, qualifiedEmployeesConfig } from "../../config/helper-config";
import LeadNotesModal from "../../components/modals/LeadNotesModal";
import AgreementModal from "../../components/modals/AgreementModal";
import SearchableInput from "../../components/SearchableInput";
import CustomTooltip from "../../components/custom/CustomTooltip";
import CreatableInput from "../../components/CreatableInput";
import SvgIcons from "../../components/SvgIcons";
import SendEmailTextComponent from "../../components/SendEmailTextComponent";
import { useSelector } from "react-redux";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import LeadStatusNotes from "../../components/modals/LeadStatusNotes";
import LeadPersonalInfoModal from "../../components/modals/LeadPersonalInfoModal";
import Swal from "sweetalert2";
import CustomTable from "../../components/Table/CustomTable";
import { BASE_URL } from "../../config";
import { getToken } from "../../http/token-interceptor";

const headerKeys = [
  { label: "_id", id: "_id" },
  { label: "Company Name", id: "companyName", isSort: true },
  { label: "PoC", id: "name", isSort: true },
  { label: "Affiliate", id: "affiliate" },
  { label: "Organization Name", id: "organizationName", isSort: true },
  { label: "Organization Leader", id: "organizationLeader" },
  { label: "Added On", id: "createdAt", isSort: true },
  {
    label: "Estimated Employees",
    id: "questionnaireEmployeeCount",
    isSort: true,
  },
  { label: "Qualified Employees", id: "qualifiedEmployees", isSort: true },
  { label: "Do Not Contact", id: "doNotContact" },
  { label: "Notes", id: "notes" },
  { label: "Stuck", id: "isStucked" },
  { label: "Status", id: "status" },
  { label: "Message", id: "message" },
  // { label: "Action", id: "action" },
];

const LeadsPage = () => {
  const navigate = useNavigate();

  const userCredential = useSelector((state) => state?.userCredential);

  const userType = useMemo(() => userCredential?.user?.userType?.toLowerCase(), [userCredential]);

  const searchInterval = useRef({ current: {} });

  const customDateRangePickerRef = useRef({ current: null });

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    pageNumber: 0,
    page: 1,
    limit: 10,
    search: "",
    sortBy: "createdAt",
    orderBy: -1,
  });
  const [agreementModal, setAgreementModal] = useState({
    isOpen: false,
    data: null,
    templateType: null,
  });
  const [leadNotesModal, setLeadNotesModal] = useState({
    isOpen: false,
    data: null,
  });
  const [leadStatusNotes, setLeadStatusNotes] = useState({
    isOpen: false,
    data: null,
  });
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    referBy: "",
    docStatus: "",
    qualifiedEmployees: "",
    organizationId: "",
    leadsProjectStatus: "",
    isStucked: "",
    isAgreementSign: false,
  });
  const [usersList, setUsersList] = useState([]);
  const [organizationsList, setOrganizationsList] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedDataIds, setSelectedDataIds] = useState([]);
  const [leadPersonalInfoModal, setLeadPersonalInfoModal] = useState({
    isOpen: false,
  });

  const _toggleAgreementModal = (isOpen = false, data = null, templateType = null) => {
    setAgreementModal({ isOpen, data, templateType });
  };

  const _toggleLeadStatusNotes = (isOpen = false, data = null, isUpdateNote = false) => {
    setLeadStatusNotes({ isOpen, data, isUpdateNote });
  };

  const _toggleLeadNotesModal = (isOpen = false, data = null) => {
    setLeadNotesModal({ isOpen, data });
  };

  const _toggleLeadPersonalInfoModal = (isOpen = false, data = null) => {
    setLeadPersonalInfoModal({ isOpen, data });
  };

  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const _togglePopover = (id = false) => {
    setIsOpenPopover((prev) => (prev !== id ? id : false));
  };

  const _getAffiliateList = async () => {
    try {
      const res = await getAffiliateList();

      setUsersList(
        res?.user?.length
          ? res.user.map((each) => ({
              label: each.name?.full,
              value: each._id,
            }))
          : []
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getOrganizationsList = async () => {
    try {
      const res = await getOrganizationsList();

      setOrganizationsList(
        res?.organizations?.length
          ? res.organizations.map((each) => ({
              label: each.name,
              value: each._id,
            }))
          : []
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllLeads = async (payload) => {
    try {
      setLoading(true);

      const res = await getAllLeads(payload);

      setData(res?.leads?.length ? res.leads : []);
      setDataCount(res?.totalCount || 0);
      setLoading(false);
      setFiltersLoading(false);
      setSearchLoading(false);
      setStatusLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
      setFiltersLoading(false);
      setSearchLoading(false);
      setStatusLoading(false);
    }
  };

  const _onSizeChange = (limit = 10) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["limit"] = limit;
    setDataPayload(newDataPayload);
    _getAllLeads(newDataPayload);
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    localStorage.leadsPageNumber = pageNumber;
    newDataPayload["page"] = pageNumber + 1;
    setDataPayload(newDataPayload);
    _getAllLeads(newDataPayload);
  };

  const _toggleSortBy = (key) => {
    const newDataPayload = { ...dataPayload };
    if (newDataPayload.sortBy === key) {
      newDataPayload.orderBy = newDataPayload.orderBy === 1 ? -1 : 1;
    } else {
      newDataPayload.sortBy = key;
      newDataPayload.orderBy = 1;
    }
    setDataPayload(newDataPayload);
    _getAllLeads(newDataPayload);
  };

  const _onFiltersUpdated = (newFilters) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (newFilters[eachFilterKey]) {
        if (eachFilterKey === "refereAffiliateCount") {
          try {
            newDataPayload.filters[eachFilterKey] = JSON.parse(newFilters[eachFilterKey]);
          } catch (e) {}
        } else if (
          eachFilterKey === "referBy" ||
          eachFilterKey === "organizationId" ||
          eachFilterKey === "qualifiedEmployees"
        ) {
          newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey]?.value;
        } else if (eachFilterKey === "isStucked") {
          newDataPayload.filters[eachFilterKey] =
            newFilters[eachFilterKey] === "Yes" ? true : false;
        } else {
          newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
        }
      }
    });

    setDataPayload(newDataPayload);

    _getAllLeads(newDataPayload);
  };

  const _onChangeFilters = (key, value) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters[key] = value;
    setFilters(newFilters);

    _onFiltersUpdated(newFilters);
  };

  const _onDatesChange = ({ startDate, endDate }) => {
    setFiltersLoading(true);

    const newFilters = { ...filters };
    newFilters.startDate = startDate;
    newFilters.endDate = endDate;
    setFilters(newFilters);

    if ((!startDate && !endDate) || (startDate && endDate)) {
      _onFiltersUpdated(newFilters);
    }
  };

  const _onChangeSearch = (searchValue) => {
    if (searchInterval?.current?.search) clearInterval(searchInterval.current.search);
    setSearchLoading(true);
    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    setDataPayload(newDataPayload);
    searchInterval.current.search = setTimeout(() => {
      _getAllLeads(newDataPayload);
    }, 1000);
  };

  const _clearFilters = () => {
    if (customDateRangePickerRef?.current?.resetState) {
      customDateRangePickerRef.current.resetState();
    }

    setFiltersLoading(true);

    const newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      newFilters[key] = "";
    });
    setFilters(newFilters);

    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = "";
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    newDataPayload["page"] = 1;
    newDataPayload["filters"] = {};
    newDataPayload.sortBy = "createdAt";
    newDataPayload.orderBy = -1;
    setDataPayload(newDataPayload);

    _getAllLeads(newDataPayload);
  };

  const _onChangeSelectedData = (value = "all", checked = false) => {
    if (value === "every") {
      setIsSelectAll(checked);
    } else {
      setIsSelectAll(false);
      let newSelectedDataIds = [...selectedDataIds];
      if (value === "all") {
        if (checked) {
          const currentPageIds = data
            .filter((each) => !newSelectedDataIds?.includes(each._id))
            .map((each) => each._id);
          newSelectedDataIds = [...newSelectedDataIds, ...currentPageIds];
        } else {
          const currentPageIds = data.map((each) => each._id);
          newSelectedDataIds = newSelectedDataIds.filter((sid) => !currentPageIds.includes(sid));
        }
      } else {
        if (checked) {
          newSelectedDataIds.push(value);
        } else {
          const findIndex = newSelectedDataIds.findIndex((each) => each === value);
          if (findIndex >= 0) newSelectedDataIds.splice(findIndex, 1);
        }
      }
      setSelectedDataIds(newSelectedDataIds);
    }
  };

  const _onChangeStatus = async (user, status, key) => {
    try {
      if (!user?._id) {
        errorHandler({ reason: "User data not found" });
        return;
      }

      // if (key === "leadsProjectStatus") {
      //   _toggleLeadStatusNotes(true, { ...user, leadsProjectStatus: status });
      //   return;
      // }

      setStatusLoading(true);

      const payload = {
        [key]: status,
      };

      if (key === "isStucked") {
        payload.isStucked = status === "Yes" ? true : false;
      }

      const newData = [...data];
      const findData = newData.find((each) => each._id === user._id);
      if (findData) {
        findData[key] = payload[key];
        setData(newData);
      }

      await updateLead({ id: user._id, payload });

      setStatusLoading(false);
      showToast("User has been updated", "success");
    } catch (error) {
      errorHandler(error);
      setStatusLoading(false);
      _getAllLeads(dataPayload);
    }
  };

  const _deleteData = async (id) => {
    try {
      setDeleteLoading(id);

      const newData = [...data];
      let newDataCount = dataCount;
      const findDataIndex = newData.findIndex((each) => each._id === id);
      if (findDataIndex > -1) {
        newData.splice(findDataIndex, 1);
        setData(newData);
        newDataCount--;
        setDataCount(newDataCount);
      }

      await deleteLeadById(id);

      Swal.fire("Deleted!", "User has been deleted.", "success");
      if (data.length === 0 || newDataCount <= 10) {
        _onPageChange();
      }
      setDeleteLoading(false);
    } catch (error) {
      errorHandler(error);
      setDeleteLoading(false);
      _getAllLeads(dataPayload);
    }
  };

  const _deleteDataAlert = (data) => {
    if (!data?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this (Name: ${data?.name?.full}) User.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#31c5c7",
      cancelButtonColor: "#F16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteData(data._id);
      }
    });
  };

  // const _onChangeNotes = (user, value) => {
  //   if (searchInterval?.current?.[user._id])
  //     clearInterval(searchInterval.current[user._id]);

  //   const newData = [...data];
  //   const findData = newData.find((each) => each._id === user._id);
  //   if (findData) {
  //     findData.notes = value;
  //     setData(newData);
  //     searchInterval.current[user._id] = setTimeout(async () => {
  //       try {
  //         await updateLead({ id: user?._id, payload: { notes: value } });
  //         showToast("Notes has been updated", "success");
  //       } catch (error) {
  //         errorHandler(error);
  //       }
  //     }, 1000);
  //   }
  // };

  const _downloadClientData = async () => {
    try {
      const payload = {};

      if (typeof dataPayload?.filters === "object") {
        payload.filters = JSON.stringify(dataPayload.filters);
      }
      if (dataPayload?.search) {
        payload.search = dataPayload?.search;
      }
      if (dataPayload?.sortBy && dataPayload?.orderBy) {
        payload.sortBy = dataPayload?.sortBy;
        payload.orderBy = dataPayload?.orderBy;
      }

      const query = structureQueryParams(payload);

      const token = await getToken();

      window.open(`${BASE_URL}/download/clients/details${query}&token=${token}`, "_blank");
    } catch (error) {
      errorHandler(error);
    }
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "companyName": {
        return (
          <>
            <div className="tableUserInfo">
              <h2 onClick={() => navigate(`/${userType}/client/${row?._id}`)}>
                {row?.companyName ? capitalize(row?.companyName) : "N/A"}
              </h2>
              <div className="userContact">
                {row?.phone && (
                  <>
                    <i
                      className="fas fa-phone-alt"
                      id={`lead_phone_${row?._id}`}
                      onClick={() => copyToClipboard(`${row?.countryCode || ""}${row?.phone}`)}
                    />
                    <UncontrolledTooltip target={`lead_phone_${row?._id}`} placement="bottom">
                      {row?.countryCode} {row?.phone}
                    </UncontrolledTooltip>
                  </>
                )}
                {row?.email && (
                  <>
                    <i
                      className="far fa-envelope"
                      id={`lead_email_${row?._id}`}
                      onClick={() => copyToClipboard(row?.email)}
                    />
                    <UncontrolledTooltip target={`lead_email_${row?._id}`} placement="bottom">
                      {row?.email}
                    </UncontrolledTooltip>
                  </>
                )}
              </div>
            </div>

            {/* <div className="d-flex flex-column">
              <div className="themeColor text-overflow cursorPointer">
                {row?.companyName ? capitalize(row?.companyName) : "N/A"}
              </div>
              <div
                className="d-flex text-dark cursorPointer"
                style={{ marginTop: 7 }}
              />
            </div> */}
          </>
        );
      }
      case "name": {
        return (
          <>
            <div
              style={{
                width: 100,
                whiteSpace: "wrap",
                wordBreak: "break-all",
              }}
            >
              {row?.name?.full ? capitalize(row?.name?.full) : "N/A"}
            </div>
          </>
        );
      }
      case "affiliate": {
        return (
          <>
            {row?.isAddedByAdmin ? (
              "Admin"
            ) : row?._referBy?.name?.full ? (
              <div
                className="themeColor cursorPointer"
                onClick={() => navigate(`/${userType}/affiliate/${row?._referBy?._id}`)}
              >
                {capitalize(row?._referBy?.name?.full)}
              </div>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      case "organizationName": {
        return <>{row?.organizationName || "N/A"}</>;
      }
      case "organizationLeader": {
        return (
          <>
            <span>
              {row?._organization?._primaryLeader?.name?.full || "N/A"}
              {row?._organization?._secondaryLeader?.length ? (
                <>
                  <Button
                    color="link"
                    id={`isOpenPopover_${row?.id}`}
                    onClick={() => _togglePopover(row?.id)}
                  >
                    <span>+{row?._organization?._secondaryLeader?.length}</span>
                  </Button>
                  <Popover
                    placement="bottom"
                    isOpen={isOpenPopover === row?.id ? true : false}
                    target={`isOpenPopover_${row?.id}`}
                    toggle={() => _togglePopover(row?.id)}
                    trigger="legacy"
                  >
                    <PopoverBody>
                      <ul>
                        {row?._organization?._secondaryLeader?.map((each, index) => (
                          <li key={index}>{each?.name?.full}</li>
                        ))}
                      </ul>
                    </PopoverBody>
                  </Popover>
                </>
              ) : null}
            </span>
          </>
        );
      }
      case "createdAt": {
        return (
          <>
            <div style={{ width: 100 }}>{formatDate(row?.createdAt)}</div>
          </>
        );
      }
      case "questionnaireEmployeeCount": {
        return (
          <>
            <span id={`lead_page_estimated_employees_${row?._id}`}>
              {formatNumberInShort(row?.questionnaireEmployeeCount || 0)}
            </span>

            <CustomTooltip
              text={formatNumberInComma(row?.questionnaireEmployeeCount || 0)}
              target={`lead_page_estimated_employees_${row?._id}`}
            />
          </>
        );
      }
      case "qualifiedEmployees": {
        return (
          <>
            <span id={`lead_page_qualified_employees_${row?._id}`}>
              {formatNumberInShort(row?.qualifiedEmployees || 0)}
            </span>
            <CustomTooltip
              text={formatNumberInComma(row?.qualifiedEmployees || 0)}
              target={`lead_page_qualified_employees_${row?._id}`}
            />
          </>
        );
      }
      case "doNotContact": {
        return (
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={row?.doNotContact}
              name={`doNotContact_${row?._id}`}
              onChange={(e) => _onChangeStatus(row, e.target.checked, "doNotContact")}
            />
          </FormGroup>
        );
      }
      case "notes": {
        return (
          <>
            <Button color="link" onClick={() => _toggleLeadNotesModal(true, row)}>
              <SvgIcons type={"docFile"} /> {row?.notesCount || 0}
            </Button>
          </>
        );
      }
      case "status": {
        return (
          <>
            <div className="d-flex">
              <Input
                type="select"
                disabled={statusLoading ? true : false}
                value={row?.leadsProjectStatus}
                onChange={(e) => _onChangeStatus(row, e.target.value, "leadsProjectStatus")}
              >
                {leadStatusConfig.map((each) => (
                  <option key={each.value} value={each.value}>
                    {each.label}
                  </option>
                ))}
              </Input>{" "}
              {leadStatusConfig.find((each) => each.value === row?.leadsProjectStatus)
                ?.description ? (
                <>
                  <sup className="mx-1">
                    <SvgIcons type={"info"} id={`status_description_leads_page_${row?._id}`} />
                  </sup>
                  <CustomTooltip
                    text={
                      leadStatusConfig.find((each) => each.value === row?.leadsProjectStatus)
                        ?.description
                    }
                    target={`status_description_leads_page_${row?._id}`}
                  />
                </>
              ) : null}{" "}
              <Button color="link" onClick={() => _toggleLeadStatusNotes(true, row, true)}>
                <i className="fas fa-pencil-alt" />
              </Button>
            </div>
          </>
        );
      }
      case "isStucked": {
        return (
          <>
            <div className="d-flex">
              <Input
                type="select"
                disabled={statusLoading ? true : false}
                value={row?.isStucked ? "Yes" : "No"}
                onChange={(e) => _onChangeStatus(row, e.target.value, "isStucked")}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Input>
            </div>
          </>
        );
      }
      case "message": {
        return (
          <>
            <div className="action">
              {/* below btn when clicked should take the user to new message page */}
              <Button
                color="link"
                className="me-1"
                onClick={() => {
                  navigate(`/${userType}/message/${row?._id}`);
                }}
              >
                <i className="far fa-comment-alt" style={{ verticalAlign: -0.5 }} />{" "}
                {row?.sentCount}
              </Button>
              {/* {row?.agreement?.status === "Pending" ||
              row?.agreement?.status === "Not Sent" ? ( // enum: ["Signed", "Pending", "Not Sent"]
                <Button
                  color="link"
                  onClick={() =>
                    _toggleAgreementModal(
                      true,
                      row,
                      row?.agreement?.status === "Pending"
                        ? "Client Agreement Reminder"
                        : "Client Agreement"
                    )
                  }
                >
                  Send Agreement{" "}
                  {row?.agreement?.status === "Pending" ? "Reminder" : ""}
                </Button>
              ) : null} */}
            </div>
          </>
        );
      }
      case "action": {
        return (
          <>
            <Button
              color="link"
              className="ms-1"
              disabled={deleteLoading ? true : false}
              onClick={() => _deleteDataAlert(row)}
            >
              {deleteLoading === row?._id ? (
                <i className="fa fa-spinner fa-spin text-danger" />
              ) : (
                <i className="fa fa-trash-alt text-danger" />
              )}
            </Button>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  useEffect(() => {
    _getAffiliateList();
    _getOrganizationsList();

    const pageNumber = localStorage?.leadsPageNumber;

    _onPageChange(+pageNumber ? +pageNumber : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="pgTitleWrap">
        <h2>Clients {loading || filtersLoading ? <Spinner /> : null}</h2>
        <div className="right">
          {userType === "admin" && (
            <>
              <Button
                color="primary"
                className="floatingButton mx-1"
                onClick={() => _toggleLeadPersonalInfoModal(true)}
              >
                <span>Add Client</span>
                {/* <div className="plusIcon">
                  <SvgIcons type={"plus"} />
                </div> */}
              </Button>

              <Button
                id="downloadClientData"
                color="primary"
                className="floatingButton mx-1"
                onClick={() => _downloadClientData()}
              >
                <span>Download</span>
                {/* <div className="plusIcon">
                  <SvgIcons type={"plus"} />
                </div> */}
                <CustomTooltip text="Download CSV" target="downloadClientData" />
              </Button>
            </>
          )}

          <div className="filterBarIcon" onClick={() => setShowFilter(!showFilter)}>
            <SvgIcons type={"filter"} />
          </div>
          <Button color="link" className="btn-reset" onClick={() => _clearFilters()}>
            <SvgIcons type={"reset"} />
          </Button>
        </div>
      </div>

      <div className={`filterWrapper ${showFilter === true ? "showFilter" : ""}`}>
        <i className="fas fa-filter filterIcon" />

        <div className="filterForm">
          <div className="formGroup searchBar">
            <Label>Search</Label>
            <InputGroup className="searchTable">
              <Input
                type="text"
                name="search"
                placeholder="Search"
                autoComplete="off"
                value={dataPayload.search}
                onChange={(e) => _onChangeSearch(e.target.value)}
              />
              <InputGroupText>
                {searchLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : dataPayload.search ? (
                  <i className="fa fa-times" onClick={() => _onChangeSearch("")} />
                ) : (
                  <i className="fa fa-search" />
                )}
              </InputGroupText>
            </InputGroup>
          </div>
          <div className="formGroup">
            <Label>Added Date</Label>

            <CustomDateRangePicker
              ref={customDateRangePickerRef}
              startDate={filters?.startDate}
              endDate={filters?.endDate}
              startDateId={"startDateId_LeadPageFilter"}
              endDateId={"endDateId_LeadPageFilter"}
              onDatesChange={_onDatesChange}
            />
          </div>

          <div className="formGroup">
            <Label>Affiliate</Label>
            <SearchableInput
              options={usersList}
              value={filters.referBy}
              onChange={(value) => _onChangeFilters("referBy", value)}
            />
          </div>

          <div className="formGroup">
            <Label>Qualified Employees</Label>
            {/* <Input
                type="select"
                value={filters.qualifiedEmployees}
                name="qualifiedEmployees"
                onChange={(e) =>
                  _onChangeFilters("qualifiedEmployees", e.target.value)
                }
              >
                <option value="">All</option>
                {qualifiedEmployeesConfig.map((each, index) => (
                  <option
                    key={`qualifiedEmployees_${index}`}
                    value={JSON.stringify(each.value)}
                  >
                    {each.label}
                  </option>
                ))}

              </Input> */}

            <CreatableInput
              options={qualifiedEmployeesConfig}
              value={filters.qualifiedEmployees}
              onChange={(value) => _onChangeFilters("qualifiedEmployees", value)}
            />
          </div>

          {userType === "admin" && (
            <div className="formGroup">
              <Label>Organization Name</Label>

              <SearchableInput
                options={organizationsList}
                value={filters.organizationId}
                onChange={(value) => _onChangeFilters("organizationId", value)}
              />
            </div>
          )}

          <div className="formGroup">
            <Label>Stuck</Label>
            <Input
              type="select"
              value={filters.isStucked}
              name="isStucked"
              onChange={(e) => _onChangeFilters("isStucked", e.target.value)}
            >
              <option value="">All</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Input>
          </div>

          <div className="formGroup">
            <Label>
              Status{" "}
              {leadStatusConfig.find((each) => each.value === filters.leadsProjectStatus)
                ?.description ? (
                <>
                  <sup className="mx-1">
                    <SvgIcons type={"info"} id={`status_description_leads_page`} />
                  </sup>
                  <CustomTooltip
                    text={
                      leadStatusConfig.find((each) => each.value === filters.leadsProjectStatus)
                        ?.description
                    }
                    target={`status_description_leads_page`}
                  />
                </>
              ) : null}
            </Label>
            <Input
              type="select"
              value={filters.leadsProjectStatus}
              name="leadsProjectStatus"
              onChange={(e) => _onChangeFilters("leadsProjectStatus", e.target.value)}
            >
              <option value="">All</option>
              {leadStatusConfig.map((each) => (
                <option key={each.value} value={each.value}>
                  {each.label}
                </option>
              ))}
            </Input>
          </div>

          {/* <div className="formGroup d-flex flex-column mt-4">
              <Label style={{ opacity: 0 }}>.</Label>
              <div className="form-check">
                <Input
                  id="agreement"
                  type="checkbox"
                  role="button"
                  checked={filters.isAgreementSign}
                  onChange={(e) =>
                    _onChangeFilters("isAgreementSign", e.target.checked)
                  }
                />
                <Label check for="agreement" role="button">
                  Agreement
                </Label>
              </div>
            </div> */}
        </div>
      </div>

      {isSelectAll || selectedDataIds?.length ? (
        <>
          <div className="d-flex flex-wrap justify-content-between">
            <span>
              {isSelectAll ? (
                <>All {dataCount} Clients are selected</>
              ) : (
                <>
                  {selectedDataIds.length}{" "}
                  {selectedDataIds.length > 1 ? "Clients are" : "Client is"} selected
                </>
              )}
            </span>

            {selectedDataIds?.length >= data?.length && dataCount > dataPayload.limit ? (
              <FormGroup check className="mb-0">
                <Label for="isSelectAll_Clients">Select All {dataCount} Clients</Label>
                <Input
                  type="checkbox"
                  id="isSelectAll_Clients"
                  checked={isSelectAll}
                  onChange={(e) => _onChangeSelectedData("every", e.target.checked)}
                />
              </FormGroup>
            ) : null}
          </div>
        </>
      ) : null}

      <CustomTable
        data={data}
        dataCount={dataCount}
        dataPayload={dataPayload}
        onPageChange={_onPageChange}
        onSizeChange={_onSizeChange}
        loading={loading}
        isRowSelection={true}
        isSelectAll={isSelectAll}
        selectedDataIds={selectedDataIds}
        onChangeSelectedData={(each, checked) => _onChangeSelectedData(each?._id, checked)}
        headerKeys={headerKeys}
        dataFormat={_dataFormat}
        toggleSortBy={_toggleSortBy}
      />

      {selectedDataIds?.length ? (
        <SendEmailTextComponent
          isSelectAll={isSelectAll}
          dataPayload={dataPayload}
          selectedDataIds={selectedDataIds}
          setSelectedDataIds={setSelectedDataIds}
          setIsSelectAll={setIsSelectAll}
          userType={`Lead`}
        />
      ) : null}

      <LeadNotesModal
        isOpen={leadNotesModal.isOpen}
        data={leadNotesModal.data}
        toggle={() => _toggleLeadNotesModal()}
        onSuccess={() => _getAllLeads(dataPayload)}
      />

      <AgreementModal
        isOpen={agreementModal.isOpen}
        data={agreementModal.data}
        templateType={agreementModal.templateType}
        toggle={() => _toggleAgreementModal()}
        onSuccess={() => _getAllLeads(dataPayload)}
      />

      <LeadStatusNotes
        isOpen={leadStatusNotes.isOpen}
        data={leadStatusNotes.data}
        isUpdateNote={leadStatusNotes.isUpdateNote}
        toggle={() => _toggleLeadStatusNotes()}
        onSuccess={() => _getAllLeads(dataPayload)}
      />

      {leadPersonalInfoModal.isOpen && (
        <LeadPersonalInfoModal
          isOpen={leadPersonalInfoModal.isOpen}
          toggle={() => _toggleLeadPersonalInfoModal()}
          onSuccess={() => _getAllLeads()}
        />
      )}
    </>
  );
};

export default LeadsPage;
